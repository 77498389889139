export const calcTotal = (
    quantity,
    quantityNewBottles,
    quantityStarterKit,
    quantityDispenser,
    currency,
    products,
    settings,
    firstOrderFree,
) => {
    let total = 0;
    const { usdves_value } = settings;

    const refill = products.find((item) => item.field === 'quantity');
    const newBottle = products.find((item) => item.field === 'quantity_new_bottles');
    const starterKit = products.find((item) => item.field === 'quantity_starterkit');
    const dispenser = products.find((item) => item.field === 'quantity_dispenser');

    let subtotalRefill = 0;
    switch (currency) {
        case 'USD':
            // get wholesale amount to calc subtotal
            if (quantity >= 5) {
                subtotalRefill = Number(quantity) * refill.amount_usd_wholesale;
            } else {
                subtotalRefill = Number(quantity) * refill.amount_usd;
            }
            total =
                subtotalRefill +
                Number(quantityNewBottles) * newBottle.amount_usd +
                Number(quantityStarterKit) * starterKit.amount_usd +
                Number(quantityDispenser) * dispenser.amount_usd;

            // for free purchases
            // discount the detail or wholesale price
            if (firstOrderFree) {
                if (quantity >= 5) {
                    total = total - refill.amount_usd_wholesale;
                } else {
                    total = total - refill.amount_usd;
                }
            }
            return total;
        case 'COP':
            if (quantity >= 5) {
                subtotalRefill = Number(quantity) * refill.amount_cop_wholesale;
            } else {
                subtotalRefill = Number(quantity) * refill.amount_cop;
            }
            total =
                subtotalRefill +
                Number(quantityNewBottles) * newBottle.amount_cop +
                Number(quantityStarterKit) * starterKit.amount_cop +
                Number(quantityDispenser) * dispenser.amount_cop;
            if (firstOrderFree) {
                if (quantity >= 5) {
                    total = total - refill.amount_cop_wholesale;
                } else {
                    total = total - refill.amount_cop;
                }
            }
            return total;
        case 'VES':
            if (quantity >= 5) {
                subtotalRefill = Number(quantity) * refill.amount_usd_wholesale;
            } else {
                subtotalRefill = Number(quantity) * refill.amount_usd;
            }
            total =
                (subtotalRefill +
                    Number(quantityNewBottles) * newBottle.amount_usd +
                    Number(quantityStarterKit) * starterKit.amount_usd +
                    Number(quantityDispenser) * dispenser.amount_usd) *
                Number(usdves_value);
            if (firstOrderFree) {
                if (quantity >= 5) {
                    total = total - refill.amount_usd_wholesale * Number(usdves_value);
                } else {
                    total = total - refill.amount_usd * Number(usdves_value);
                }
            }
            return total;
        default:
            return total;
    }
};

const getBottleString = (quantity) => (quantity <= 1 ? `${quantity}` : `${quantity}`);

const plural = (quantity) => (quantity > 1 ? 's' : '');

export const getBottlesPurchase = (quantityPurchase, newBottlesClient) => {
    if (newBottlesClient === 0) {
        return `${getBottleString(quantityPurchase)} usado${plural(quantityPurchase)}`;
    } else {
        if (newBottlesClient >= quantityPurchase) {
            return `${getBottleString(quantityPurchase)} nuevo${plural(quantityPurchase)}`;
        } else if (newBottlesClient < quantityPurchase) {
            return `${getBottleString(newBottlesClient)} nuevo${plural(
                newBottlesClient,
            )} y ${getBottleString(quantityPurchase - newBottlesClient)} usado${plural(
                quantityPurchase - newBottlesClient,
            )}`;
        }
    }
};

export const getShortBottlesPurchase = (quantityPurchase, newBottlesClient) => {
    if (newBottlesClient === 0) {
        return `${quantityPurchase} usado${plural(quantityPurchase)}`;
    } else {
        if (newBottlesClient >= quantityPurchase) {
            return `${quantityPurchase} nuevo${plural(quantityPurchase)}`;
        } else if (newBottlesClient < quantityPurchase) {
            return `${newBottlesClient} nuevo${plural(newBottlesClient)} y ${
                quantityPurchase - newBottlesClient
            } usado${plural(quantityPurchase - newBottlesClient)}`;
        }
    }
};

export const getTimeFormat = (deliveryTime) => {
    let hour = Number(deliveryTime.split(':')[0]);
    let minutes = Number(deliveryTime.split(':')[1]);
    const amPM = hour > 11 ? 'pm' : 'am';

    hour = hour > 12 ? hour - 12 : hour;
    minutes = minutes === 0 ? '00' : minutes;

    return `${hour}:${minutes} ${amPM}`;
};

export function getMessageForDeliveryOption(deliverySelected) {
    const currentDate = new Date();
    const currentHourPicker = currentDate.getHours();
    const currentWeekDay = Number(currentDate.getDay());
    const subnameDelivery = deliverySelected.subname;

    const scheduleStartTime = deliverySelected.startTime.split(':')[0];
    const scheduleEndTime = deliverySelected.endTime.split(':')[0];

    let message = null;

    if (currentWeekDay === deliverySelected.weekday) {
        // antes de empezar el día
        if (currentHourPicker < Number(deliverySelected.startTime.split(':')[0])) {
            message = 'Hoy a primera hora (8:00am - 8:30am)';
        } else if (currentHourPicker >= scheduleStartTime && currentHourPicker < scheduleEndTime) {
            // durante el horario laboral
            message = 'Entrega express (30 minutos)';
        }
    } else {
        // siguiente día laboral
        if (currentWeekDay + 1 === deliverySelected.weekday) {
            message = `Mañana (${subnameDelivery}) a primera hora (8:00am - 8:30am)`;
        } else {
            // próximo dia laboral (el siguiente dia laboral no se trabaja)
            // ejemplo: se consulta el sábado y el domingo no se trabaja
            // retornar el lunes a primera hora
            message = `${subnameDelivery} a primera hora (8:00am - 8:30am)`;
        }
    }
    return message;
}

export const emojiRequested = {
    bot: '🤖',
    admin: '🧑🏽‍💻',
    app: '📱',
};
