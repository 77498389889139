import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import useForm from 'react-hook-form';

const ModalObservation = (props) => {
    const { visible, onSave, handleClose, observationClosestPurchase, transactionId, purchase } =
        props;
    const { handleSubmit, register } = useForm();

    const [pagoPendiente, setPagoPendiente] = useState(false);
    const [vacioPendiente, setVacioPendiente] = useState(false);
    const [montoFavor, setMontoFavor] = useState(false);

    const buildObservationMessage = (
        pagoPendienteMsg,
        vacioPendienteMsg,
        montoFavorMsg,
        observacionFinalMsg,
    ) => {
        let allFieldsNull = true;

        Object.values({
            pagoPendienteMsg,
            vacioPendienteMsg,
            montoFavorMsg,
            observacionFinalMsg,
        }).map((value) => {
            if (!!value) allFieldsNull = false;
        });

        if (allFieldsNull) return null;

        const paymentNameShort = purchase.paymentMethod.name.split(' ')[0].toLowerCase();
        const pagoPendienteText = pagoPendienteMsg
            ? `Pago pendiente: ${pagoPendienteMsg} ${paymentNameShort}. `
            : '';
        const vacioPendienteText = vacioPendienteMsg
            ? `${vacioPendienteMsg} vacio(s) por reclamar. `
            : '';
        const montoFavorText = montoFavorMsg
            ? `Tiene a favor: ${montoFavorMsg} ${paymentNameShort}. `
            : '';
        const observacionFinalText = observacionFinalMsg ? `*${observacionFinalMsg}.` : '';

        return `${pagoPendienteText}${vacioPendienteText}${montoFavorText}${observacionFinalText}`;
    };

    const handleSave = (data) => {
        const observacionFinal = buildObservationMessage(
            data.pagoPendienteMsg,
            data.vacioPendienteMsg,
            data.montoFavorMsg,
            data.observationMsg,
        );

        // transactionId is undefined when order is delivered
        // is ! null when transaction need to be updated
        onSave(observacionFinal, transactionId);
    };

    return (
        <Modal open={visible} onClose={handleClose} classNames={{ modal: 'customModal' }}>
            <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    Nota en el pedido
                </h5>
            </div>
            <div className="modal-body modal-body-observation">
                {observationClosestPurchase && (
                    <div className="form-group">
                        {observationClosestPurchase}
                        <hr />
                    </div>
                )}
                <div className="form-group">
                    <div>
                        <div>
                            <input
                                type="checkbox"
                                id="pagoPendiente"
                                name="contact"
                                value="express"
                                checked={pagoPendiente}
                                onChange={() => setPagoPendiente(!pagoPendiente)}
                            />
                            &nbsp;&nbsp;
                            <label htmlFor="pagoPendiente">Pago pendiente</label>
                        </div>
                        {pagoPendiente && (
                            <input
                                className="form-control mb-3"
                                placeholder="Ejm: 12.000 ó 4.000"
                                name="pagoPendienteMsg"
                                type="text"
                                inputMode="numeric"
                                ref={register({
                                    required: false,
                                })}
                            />
                        )}
                    </div>
                    <div>
                        <div>
                            <input
                                type="checkbox"
                                id="vacioPendiente"
                                name="contact"
                                value="express"
                                checked={vacioPendiente}
                                onChange={() => setVacioPendiente(!vacioPendiente)}
                            />
                            &nbsp;&nbsp;
                            <label htmlFor="vacioPendiente">Vacio(s) pendiente</label>
                        </div>
                        {vacioPendiente && (
                            <input
                                className="form-control mb-3"
                                placeholder="Ejm: 1 ó 2"
                                name="vacioPendienteMsg"
                                type="text"
                                inputMode="numeric"
                                ref={register({
                                    required: false,
                                })}
                            />
                        )}
                    </div>
                    <div>
                        <div>
                            <input
                                type="checkbox"
                                id="montoFavor"
                                name="contact"
                                value="express"
                                checked={montoFavor}
                                onChange={() => setMontoFavor(!montoFavor)}
                            />
                            &nbsp;&nbsp;
                            <label htmlFor="montoFavor">Monto a favor</label>
                        </div>
                        {montoFavor && (
                            <input
                                className="form-control mb-3"
                                placeholder="Ejm: 12.000 ó 4.000"
                                name="montoFavorMsg"
                                type="text"
                                inputMode="numeric"
                                ref={register({
                                    required: false,
                                })}
                            />
                        )}
                    </div>
                    <textarea
                        type="text"
                        className="form-control"
                        name="observationMsg"
                        rows="4"
                        ref={register({
                            required: false,
                        })}
                        placeholder="Complementa aquí una nota respecto a la entrega."
                    />
                </div>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit(handleSave)}
                >
                    guardar
                </button>
            </div>
        </Modal>
    );
};

export default ModalObservation;
