import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import ReactTable from 'react-table';
import Loader from '../common/loader';
import api from '../../config/axios';
import { date, time, dateQuery } from '../../utils/date';
import miscUtils from '../../utils/miscUtils';
import { useStateValue } from '../../store';
import { getTimeFormat, getShortBottlesPurchase } from './utils';
import OrderItem from './common/order-item';

const { currency } = miscUtils;

const columnsDataTable = (count, role) => [
    {
        Header: role !== 'DELIVERY' ? <b>{`${count} pedidos`}</b> : null,
        id: 'delete',
        accessor: (str) => 'delete',
        Cell: (row) => <OrderItem data={row.original} />,
        style: {
            textAlign: 'center',
            width: '100%',
        },
        sortable: false,
        width: '100%',
    },
];

const arrayFilter = [
    {
        label: 'Últimos 7 días',
        value: 'week',
    },
    {
        label: 'Este mes',
        value: 'currently-month',
    },
    {
        label: 'Mes pasado',
        value: 'last-month',
    },
];

const getDatesRange = (type) => {
    let dates = { startDate: null, endDate: null };
    const startDate = new Date();
    const endDate = new Date();

    switch (type) {
        case 'week':
            endDate.setDate(endDate.getDate() + 1);
            dates.endDate = dateQuery(endDate);
            startDate.setDate(startDate.getDate() - 7);
            dates.startDate = dateQuery(startDate);
            break;
        case 'currently-month':
            dates.startDate = dateQuery(new Date(startDate.getFullYear(), startDate.getMonth(), 1));
            dates.endDate = dateQuery(new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0));
            break;
        case 'last-month':
            dates.startDate = dateQuery(
                new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1),
            );
            dates.endDate = dateQuery(new Date(endDate.getFullYear(), endDate.getMonth(), 0));
            break;
    }
    return dates;
};

const getLastTransaction = (transactions) =>
    transactions.reduce((a, b) => (a.createdAt > b.createdAt ? a : b));

const getFirstAndLastDateOfTheWeek = () => {
    var curr = new Date();
    curr.setHours(0, 0, 0, 0); // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6

    var firstday = new Date(curr.setDate(first + 1));
    var lastday = new Date(curr.setDate(firstday.getDate() + 6));

    return {
        first: firstday,
        last: lastday,
    };
};

function Orders(props) {
    const [{ auth }] = useStateValue();
    const [loaded, setLoaded] = useState(false);
    const [orders, setOrders] = useState([]);
    const [productsDelivered, setProductsDelivered] = useState(null);
    const [longwaysDelivered, setLongways] = useState(null);
    const [pagination, setPagination] = useState({ page: 1 });
    const [pageTable, setPageTable] = useState(0);
    const [filterOrders, setFilterOrders] = useState('week');
    const [deliveryUsers, setDeliveryUsers] = useState([]);
    const [showStatitics, setShowStatitics] = useState(false);
    const { first: mondayWeek, last: sundayWeek } = getFirstAndLastDateOfTheWeek();

    useEffect(() => {
        setLoaded(false);
        const { startDate, endDate } = getDatesRange(filterOrders);
        Promise.all([
            fetchOrders(pagination.page, startDate, endDate),
            fetchDeliveredProducts(
                mondayWeek,
                sundayWeek,
                auth.user.franchiseId || '5e95f51f-5b66-4c58-b2af-7a54e8b1df1e',
            ),
            fetchLongways(
                mondayWeek,
                sundayWeek,
                auth.user.franchiseId || '5e95f51f-5b66-4c58-b2af-7a54e8b1df1e',
            ),
        ]).then((values) => {
            setProductsDelivered(values[1]);
            setLongways(values[2]);
            setLoaded(true);
        });
    }, [filterOrders]);

    const fetchOrders = async (page, startDate, endDate) => {
        const { data } = await api.get(
            `purchase/franchise?page=${page}&startDate=${startDate}&endDate=${endDate}`,
        );
        let orders = null;

        if (auth.user.role === 'DELIVERY') {
            // mostrar solo las ordenes que han sido creadas y han sido enviadas
            orders = parseToDatatable(
                data.data.rows.filter((o) => {
                    const lastTransaction = getLastTransaction(o.transactions);
                    if (
                        lastTransaction.name === 'Pedido Creado' ||
                        lastTransaction.name === 'Pedido Enviado' ||
                        lastTransaction.name === 'Pago Confirmado' ||
                        o.userId === auth.user.id
                    ) {
                        return true;
                    }
                    return false;
                }),
            );
        } else {
            // mostrar todas las ordenes (creadas, enviadas y entregadas)
            orders = parseToDatatable(data.data.rows);
        }

        const dataPagination = {
            ...data.meta.pagination,
            total: data.data.count,
        };
        setOrders(orders);
        setPagination(dataPagination);
        setPageTable(data.meta.pagination.page - 1);

        // mostrar el listado de usuarios deliveries
        // en el Select de deliveries
        handleDeliveryUsers(data.data.rows);
        return;
    };

    const fetchDeliveredProducts = async (startDate, endDate, franchiseId) => {
        try {
            const { data } = await api.get(
                `purchase/per-delivery?startDate=${startDate}&endDate=${endDate}&franchiseId=${franchiseId}`,
            );
            return data.data;
        } catch (e) {
            throw new Error(e);
        }
    };

    const fetchLongways = async (startDate, endDate, franchiseId) => {
        try {
            const { data } = await api.get(
                `purchase/per-delivery-longway?startDate=${startDate}&endDate=${endDate}&franchiseId=${franchiseId}`,
            );
            return data.data;
        } catch (e) {
            throw new Error(e);
        }
    };

    const handleDeliveryUsers = (array) => {
        const arrayOfUsersRepetead = array.filter((f) => f.user).map((m) => m.user);

        // se eliminan los valores repetidos del array
        // se hace match por el id para manejar el array de objetos
        const arrayOfUsers = arrayOfUsersRepetead.filter(
            (value, index, self) =>
                index === self.findIndex((t) => t.id === value.id && t.name === value.name),
        );
        setDeliveryUsers(arrayOfUsers);
    };

    const parseToDatatable = (array) => {
        const parsed = array.map((item) => {
            const lastTransaction = item.transactions.reduce((a, b) =>
                a.createdAt > b.createdAt ? a : b,
            );
            const firstTransaction = item.transactions.reduce((a, b) =>
                b.createdAt > a.createdAt ? a : b,
            );
            const productRecargas = item.products.find((item) => item.name === 'Recarga');
            const quantityOrder = productRecargas
                ? getShortBottlesPurchase(
                      productRecargas.purchaseProducts.quantity,
                      item.address.newBottles,
                  )
                : '';

            // bandera para mostrar a primera hora
            const hourPurchase = new Date(firstTransaction.createdAt).getHours();
            let firstMomentNextDate = false;

            const purchaseWeekday = new Date(item.createdAt).getDay();
            const deliveryWeekday = item.delivery.weekday;

            // delivery nuevo (schedule)
            if (deliveryWeekday) {
                // en lugar de 8, hay que colocar el startTime del delivery
                if (purchaseWeekday === deliveryWeekday && hourPurchase < 8) {
                    firstMomentNextDate = true;
                } else if (purchaseWeekday !== deliveryWeekday) {
                    firstMomentNextDate = true;
                }
            } else {
                // delivery viejo
                // horario normal
                if (hourPurchase > 8 && hourPurchase < 18) {
                    // no se hace nada
                } else {
                    // horario fuera del habitual
                    firstMomentNextDate = true;
                }
            }

            const itemFormatted = {
                id: item.id,
                estado: renderBadge(lastTransaction.name),
                carreraLarga: item.address.longway ? renderLongway() : null,
                clienteNuevo: item.oldPurchases.length === 1 ? badgeNewClient() : null,
                entrega: `${
                    item.delivery_time
                        ? getTimeFormat(item.delivery_time)
                        : firstMomentNextDate
                        ? `8:00 am - Express`
                        : `${time(firstTransaction.createdAt)} - Express`
                }`,
                delivery_time: item.delivery_time,
                vueltos: item.changeCash,
                'fecha de pedido': `${date(item.createdAt)} ${time(firstTransaction.createdAt)}`,
                cantidad: miscUtils.renderTextPedido(item),
                quantityDescription: quantityOrder,
                cliente: item.client.name,
                direccion: item.address ? item.address.street1 : item.addressQuick,
                user: item.user,
                paymentMethod: item.paymentMethod.name,
                observation: purchaseWithNotes(item.oldPurchases),
                couponId: item.couponId,
                observationBotellon: item.address.observation,
                subscription: item.subscriptionId,
                preNote: item.preNote,
                requestedFrom: item.requestedFrom,
                payment_confirmation_id: item.payment_confirmation_id,
            };

            return itemFormatted;
        });
        return parsed;
    };

    const renderBadge = (status) => {
        switch (status) {
            case 'Pedido Creado':
                return <span className="badge badge-warning">Por enviar</span>;
            case 'Pedido Cancelado':
                return <span className="badge badge-danger">Cancelado</span>;
            case 'Pedido Enviado':
                return <span className="badge badge-info">Enviado</span>;
            case 'Pedido Recibido':
                return <span className="badge badge-success">Recibido</span>;
            case 'Pedido Entregado':
                return <span className="badge badge-success">Entregado</span>;
            case 'Pedido Rechazado':
                return <span className="badge badge-danger">Rechazado</span>;
            case 'Pago Confirmado':
                return <span className="badge badge-warning">Pago Confirmado</span>;
            default:
                return null;
        }
    };

    const renderLongway = () => <span className="badge badge-info">Larga</span>;

    const badgeNewClient = () => <span className="badge badge-danger">Cliente Nuevo</span>;

    const handlePage = async (page) => {
        setLoaded(false);
        const { startDate, endDate } = getDatesRange(filterOrders);
        await fetchOrders(page, startDate, endDate);
        setLoaded(true);
    };

    const refreshPage = async () => {
        setLoaded(false);
        const { startDate, endDate } = getDatesRange(filterOrders);
        await fetchOrders(0, startDate, endDate);
        setLoaded(true);
    };

    const filterOrdersByDelivery = (userEmail) => {
        const newOrders = orders
            .filter((order) => order.user)
            .filter((order) => order.user.email === userEmail);
        setOrders(newOrders);
    };

    const quantityDeliveredBottlesByDelivery = () => {
        let quantityByUserDelivery = 0;
        if (auth.user.franchiseId) {
            const userDeliveredOrder = productsDelivered.find(
                (products) => products.userId === auth.user.id,
            );
            if (!!userDeliveredOrder) {
                quantityByUserDelivery = productsDelivered.find(
                    (products) => products.userId === auth.user.id,
                ).quantity;
            } else {
                quantityByUserDelivery = 0;
            }
        } else {
            quantityByUserDelivery = productsDelivered
                .map((e) => e.quantity)
                .reduce((a, b) => Number(a) + Number(b));
        }

        return quantityByUserDelivery;
    };

    const quantityDeliveredLongBottlesByDelivery = () => {
        let qtyLongByUserDelivery = 0;
        if (auth.user.franchiseId) {
            const userDeliveredOrder = longwaysDelivered.find(
                (long) => long.userId === auth.user.id,
            );
            if (!!userDeliveredOrder) {
                qtyLongByUserDelivery = longwaysDelivered.find(
                    (long) => long.userId === auth.user.id,
                ).quantity;
            } else {
                qtyLongByUserDelivery = 0;
            }
        } else {
            qtyLongByUserDelivery = longwaysDelivered
                .map((e) => e.quantity)
                .reduce((a, b) => Number(a) + Number(b));
        }

        return qtyLongByUserDelivery;
    };

    const totalPages = () => Math.ceil(pagination.total / pagination.length);

    const purchaseWithNotes = (array) => {
        // commented 12/03/24
        // const observations = array.filter((item) =>
        //     item.transactions.find((transaction) => !!transaction.observation),
        // );

        const ultimoPedido = array.reduce((a, b) => (a.id > b.id ? a : b));

        // el ultimo pedido que está en el array
        // puede ser:
        // 1. pedido (lista) NO se ha entregado (pendiente por entregar)
        // 2. pedido (lista) YA se entregó
        //
        // si es 1, hay que revisar las notas del último pedido entregado
        // si es 2, revisar las notas del pedido de la lista
        const ultimoPedidoHaSidoEntregado = ultimoPedido.transactions.find(
            (item) => item.name === 'Pedido Entregado',
        );

        // caso 2
        // pedido de la lista ya fue entregado
        if (ultimoPedidoHaSidoEntregado) {
            const notaUltimoPedido = ultimoPedido.transactions.find(
                (transaction) => !!transaction.observation,
            );

            return !!notaUltimoPedido;
        } else {
            // caso 1
            // pedido de la lista no ha sido entregado
            const observations = array.filter((item) =>
                item.transactions.find((transaction) => !!transaction.observation),
            );

            return observations.length > 0;
        }
    };

    // se hace uso del array de deliveries
    // y se hace uso del array de productsDelivered
    // para hacer el conteo de entregas por cada delivery
    const renderOrdersByUser = () => {
        const render = deliveryUsers.map((item, index) => {
            if (productsDelivered) {
                const userDeliveredOrder = productsDelivered.find(
                    (products) => products.userId === item.id,
                );

                let longways = 0;
                const userLongwaysDelivered = longwaysDelivered.find(
                    (longs) => longs.userId === item.id,
                );

                if (!!userLongwaysDelivered) {
                    longways = userLongwaysDelivered.quantity;
                }
                if (!!userDeliveredOrder) {
                    return (
                        <span style={{ fontSize: 12 }} key={`user-delivery-${index}`}>
                            <Link
                                to={`${process.env.PUBLIC_URL}/dashboard/${item.franchiseId}/users/${item.id}`}
                                style={{ color: 'darkblue' }}
                            >
                                {item.name.split(' ')[0]}
                            </Link>
                            : <strong>{userDeliveredOrder.quantity}</strong> ({longways})
                            <br />
                        </span>
                    );
                }
                return null;
            }
            return null;
        });
        return render;
    };

    if (!loaded)
        return (
            <div style={{ display: 'flex', height: '50vh' }}>
                <Loader />
            </div>
        );

    const renderedOrdersByUser = renderOrdersByUser();
    const columns = columnsDataTable(pagination.total, auth.user.role);

    return (
        <Fragment>
            <div className="container-fluid rents-wrapper">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="d-flex justify-content-between align-items-center pt-2 pb-2">
                            <div>
                                {auth.user.role !== 'DELIVERY' && (
                                    <Link
                                        to={`${process.env.PUBLIC_URL}/recargas/nueva-recarga`}
                                        className="btn btn-primary"
                                    >
                                        Nuevo pedido
                                    </Link>
                                )}
                                &nbsp;&nbsp;
                                {auth.user.role === 'ADMIN' && (
                                    <button
                                        className="btn btn-warning"
                                        onClick={() => setShowStatitics(!showStatitics)}
                                    >
                                        <i className="fa fa-eye"></i>
                                    </button>
                                )}
                            </div>
                            <div>
                                <button className="btn btn-success" onClick={refreshPage}>
                                    <i className="fa fa-refresh"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {auth.user.role !== 'DELIVERY' && showStatitics && (
                    <div className="row">
                        <div className="col-xl-4 col-lg-6 col-12 mt-1 mb-2">
                            <Select
                                name="filter-orders"
                                onChange={(value) => setFilterOrders(value.value)}
                                defaultValue={arrayFilter.find((e) => e.value === filterOrders)}
                                options={arrayFilter}
                            />
                        </div>
                        {deliveryUsers.length > 1 && (
                            <div className="col-xl-4 col-lg-6 col-12 mt-1 mb-2">
                                <Select
                                    name="filter-users"
                                    onChange={(value) => filterOrdersByDelivery(value.value)}
                                    defaultValue={deliveryUsers[0].email}
                                    options={deliveryUsers.map((i) => ({
                                        label: i.name,
                                        value: i.email,
                                    }))}
                                />
                            </div>
                        )}
                    </div>
                )}
                <div className="row">
                    {!!productsDelivered && productsDelivered.length > 0 && (
                        <div className="col-sm-12 d-flex justify-content-between mt-2 mb-2">
                            <span style={{ fontSize: 14 }}>
                                <div>
                                    {mondayWeek.toLocaleDateString('es-VE')} -{' '}
                                    {sundayWeek.toLocaleDateString('es-VE')}
                                </div>
                            </span>
                            <div>
                                <span style={{ fontSize: 14 }}>
                                    {auth.user.role === 'DELIVERY' ? 'Tus' : 'Total'} entregas:{' '}
                                    <strong>{quantityDeliveredBottlesByDelivery()}</strong>&nbsp;
                                    {auth.user.role === 'DELIVERY'
                                        ? `(${quantityDeliveredLongBottlesByDelivery()})`
                                        : ''}
                                    {/* Largas: {quantityDeliveredLongBottlesByDelivery()} */}
                                </span>
                                <br />
                                {showStatitics && (
                                    <div style={{ textAlign: 'right' }}>{renderedOrdersByUser}</div>
                                )}
                            </div>
                        </div>
                    )}
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div id="basicScenario" className="order-list">
                                    <ReactTable
                                        columns={columns}
                                        className="-striped "
                                        multiSelectOption={false}
                                        data={orders}
                                        page={pageTable}
                                        pages={totalPages()}
                                        defaultPageSize={pagination.length}
                                        showPageSizeOptions={false}
                                        onPageChange={(index) => handlePage(index + 1)}
                                        manual
                                        nextText="Siguiente"
                                        previousText="Anterior"
                                        getTrProps={(state, rowInfo, column, instance) => ({
                                            onClick: (e) =>
                                                props.history.push(
                                                    `/recargas/detalle/${rowInfo.original.id}`,
                                                ),
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Orders;
